/* eslint-disable react/no-unknown-property */
export default function Custom404() {
  return (
    <>
      <h1>404 - Page Not Found</h1>
      <style jsx>{`
        h1 {
          display: flex;
          justify-content: center;
          align-items: center;
          color: #000;
          font-size: 3rem;
          font-weight: 600;
          line-height: 1.2;
          margin: 0;
          padding: 0;
        }
      `}</style>
    </>
  );
}
